import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'

const NewsStyles = styled.article`
  .news__image {
    margin-bottom: 4rem;
  }
`

export default function SingleNewsPage({ data, pageContext: {
  breadcrumb: { crumbs },
  } }) {
  return (
    <>
      <Layout>
        <SEO
          title={data.nodeMicrositeNews.title}
          description={data.nodeMicrositeNews.field_microsite_teaser}
        />
        <NewsStyles>
          <div className="layout-contained padding-horizontal">
            <div className="layout-contained__article-width">
            <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
              <h1>{data.nodeMicrositeNews.title}</h1>
              {data.nodeMicrositeNews.relationships?.field_microsite_image ? (
                <GatsbyImage
                  className="news__image"
                  image={
                    data.nodeMicrositeNews.relationships.field_microsite_image
                      .localFile.childImageSharp.gatsbyImageData
                  }
                  alt={
                    data.nodeMicrositeNews.field_microsite_image.alt
                      ? data.nodeMicrositeNews.field_microsite_image.alt
                      : data.nodeMicrositeNews.title
                  }
                />
              ) : (
                <div>{data.nodeMicrositeNews.field_microsite_teaser}</div>
              )}
              <div
                className="body-content"
                dangerouslySetInnerHTML={{
                  __html: data.nodeMicrositeNews.field_microsite_body.value,
                }}
              />
            </div>
          </div>
        </NewsStyles>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($id: Int!) {
    nodeMicrositeNews(drupal_internal__nid: { eq: $id }) {
      id
      title
      field_microsite_body {
        value
      }
      field_microsite_teaser
      field_microsite_image {
        alt
      }
      relationships {
        field_microsite_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 650)
            }
          }
        }
      }
    }
  }
`
